<template>
    <st-page :title="titleComputed" :backLink="backLink" layout="tab" pageCustomClass="application-view">
        <ApplicationRegisterForm 
            :is-new="true"
            @form-success="handleSuccess"
            @form-error="handleError"
            :record="record"
        ></ApplicationRegisterForm>
    </st-page>
</template>

<script>
import ApplicationRegisterForm from "@/modules/applications/components/ApplicationRegisterForm.vue";
import { mapGetters } from 'vuex';

export default {
    name: "ApplicationRegister",
    components: { ApplicationRegisterForm },
    props: {
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            record: 'applications/form/record',
        }),
        titleComputed() {
            return this.$t("APPLICATION_REGISTER.TITLE");
        },
        backLink() {
            return { name: "dashboard" };
        },
    },
    methods: {
        handleSuccess(applicationData) {
            this.showSuccessModal(applicationData);
        },
        handleError() {
            this.showErrorModal();
        },
        printContent(applicationData) {

            const id = applicationData?.identification_number || applicationData?.allocated_document_series;
            // Create an iframe
            const printFrame = document.createElement('iframe');
            printFrame.style.position = 'absolute';
            printFrame.style.width = '0';
            printFrame.style.height = '0';
            printFrame.style.border = 'none';
            document.body.appendChild(printFrame);

            const doc = printFrame.contentWindow.document;
            doc.open();
            doc.write('<html><head><title>Print</title>');
            doc.write('<style>');
            doc.write('body { margin: 0; display: flex; justify-content: center; align-items: center; flex-direction: column; padding-top: 60px; text-align: center; width: 350px; margin: 0 auto; } .print-text-number { border: 1px solid #E8E8E8; padding: 12px; border-radius: 4px; margin-bottom: 12px; font-size: 18px; font-weight: bold; } .print-text-date { color: #808080; font-size: 14px; }');
            doc.write('@media print {');
            doc.write('  @page { margin: 0; }'); // Remove default page margins
            doc.write('  body { -webkit-print-color-adjust: exact; }');
            doc.write('}');
            doc.write('</style>');
            doc.write('</head><body>');
            
            doc.write('<h2 class="print-title">Confirmare Înregistrare</h2>');
            doc.write('<div class="print-text">');
            doc.write('<p>Documentul dumneavoastră a fost înregistrat cu succes în sistemul nostru.</p>');
            doc.write(`<p class="print-text-number">Număr document: ${id}</p>`);
            doc.write(`<p class="print-text-date">Generat în dată de: ${new Date().toLocaleDateString()}</p>`);
            doc.write('</div>');
            
            doc.write('</body></html>');
            doc.close();

            printFrame.contentWindow.onload = function() {
                printFrame.contentWindow.print();
                document.body.removeChild(printFrame);
            };
        },
        printApplication(applicationData) {
            this.printContent(applicationData);
        },
        showSuccessModal(applicationData) {
            const id = applicationData?.identification_number || applicationData?.allocated_document_series;
            const title = this.$t('APPLICATION_REGISTER.NOTIFIERS.CREATED_SUCCESS_TITLE');
            const text = `${this.$t('APPLICATION_REGISTER.NOTIFIERS.CREATED_SUCCESS_TEXT', { number: id })}`;

            this.$alert({
                type: 'success',
                title,
                width: '46rem',
                text: text,
                isTextHtml: true,
                confirmButtonText: this.$t('APPLICATION_REGISTER.NOTIFIERS.CONFIRM_BUTTON'),
                cancelButtonText: this.$t('APPLICATION_REGISTER.NOTIFIERS.CANCEL_BUTTON'),
                hasConfirmation: true,
                confirmCallback: () => {
                    this.printApplication(applicationData);
                    this.$router.push('/applications');
                },
                dismissCallback: () => {
                    window.location.reload();
                },

                callback: () => {
                    this.$router.push('/applications');
                },
            });
        },
        showErrorModal() {
            const title = this.$t('APPLICATION_REGISTER.NOTIFIERS.CREATE_ERROR_TITLE');
            const text = this.$t('APPLICATION_REGISTER.NOTIFIERS.CREATE_ERROR_TEXT');
            this.$alert({
                type: 'error',
                title,
                text: text,
                isTextHtml: true,
                showConfirmButton: false,
            });
        }
    },
};
</script>